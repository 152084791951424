import React from "react"
import Layout from "../../components/storyLayout"
import SEO from "../../components/seo"

const StoryPost = () => (
  <Layout storySrc="https://www.youtube.com/embed/ejMwOJbHN3o">
    <SEO title="Getting Involved - Story" />
  </Layout>
)

export default StoryPost
